

<app-navigation></app-navigation>

  <router-outlet></router-outlet> 

  <app-bottom-nav></app-bottom-nav>







