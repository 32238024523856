
    <div class="row">
        <div class="col-md-8">
        <div class="row">
            <h2>Profile Image</h2>
            <p class="text-center">
                <img class="small" src='https://images.theatticygo.com/profile-images/{{newSettings.profile_image}}.png'>
            </p>

            <div class="col-auto">
                <label  for="customFile">Upload Image</label>
                <input (change)="onFileSelected($event)" id="packImage" formControlName ="packImage" [ngClass]="{'is-invalid': submitted && f[''].errors}" type="file" class="form-control"  />
            </div>

        </div>
        <div class="row">
            <h2>Favorite Cards</h2>

            <p class="text-center">
            <button class="button btn btn-default" (click)="selectCardEdit(1)" >
                <img class="smallcard" src='https://images.theatticygo.com/card-images/{{newSettings.favorite_card1}}.png'>
            </button>
            <button class="button btn btn-default" (click)="selectCardEdit(2)" >
                <img class="smallcard" src='https://images.theatticygo.com/card-images/{{newSettings.favorite_card2}}.png'>
            </button>
            <button class="button btn btn-default" (click)="selectCardEdit(3)" >
                <img class="smallcard" src='https://images.theatticygo.com/card-images/{{newSettings.favorite_card3}}.png'>
            </button>
            <button class="button btn btn-default" (click)="selectCardEdit(4)" >
                <img class="smallcard" src='https://images.theatticygo.com/card-images/{{newSettings.favorite_card4}}.png'>
            </button>
            <button class="button btn btn-default" (click)="selectCardEdit(5)" >
                <img class="smallcard" src='https://images.theatticygo.com/card-images/{{newSettings.favorite_card5}}.png'>
            </button>

        </p>


        </div>

        <div class="row">
            <form [formGroup]="aboutMe">
                <div class="form-group">
                    <h2><label for="aboutMe">About</label></h2>
                    <textarea formControlName="about" [ngClass]="{'is-invalid': submitted && f['about'].errors}" [(ngModel)]="about" class="form-control"  rows="3"></textarea>
                  </div>

                  <div *ngIf="submitted && f['about'].errors" class="invalid-feedback">
                    asd
                    <div *ngIf="f['about'].errors['required']">
                      <p class="red">500 characters max for your About</p>
                    </div>
                </div>
            </form>












            <button [disabled] = "done==true" class=" smallbtn btn button btn-success"(click)="saveImages()">Save</button>
        </div>








    </div>
    <div class="col mr-auto text-center"  >
            <div class="row" (keydown.enter)="submitSearch()">
                <div class="row">
                    <label for="Name">Name</label>
                    <input class="form-control" type="text" [(ngModel)] = "filters.name">
                    <button class="btn btn-success" (click)="submitSearch()">Search</button>
                </div>

                <div class="cards row-lg-8 overflow-auto">
                    <div class="d-flex justify-content-center">
                            <div id="Loading" class="spinner-border" role="status">
                                <span class="sr-only" ></span>
                            </div>
                    </div>

                        <span *ngFor = "let card of currentCards">

                            <button  class="btn btn-default"   (click)="setCard(card.id)" ><img class ="smallcard" src='https://images.theatticygo.com/card-images/{{card?.id}}.png?{{getTimeStamp()}}'></button>
                        </span>


                </div>

                <div class="row">
                    <button class="btn btn-success" (click)="nextPage()">Next</button><button class="btn btn-success" (click)="prevPage()">Previous</button>
                </div>

            </div>
        </div></div>


