<app-hover-card></app-hover-card>
<div class="container">

    <h1 class="text-center">{{deckInfo.title}}</h1>
    <h4 class="text-center">Created by <a routerLink="/players/{{deckInfo.creatorid}}">{{deckInfo.creator}}</a></h4>
    <span class="packdescription" [innerHTML]="deckInfo.packdescription"></span>

    <!-- <button class="btn btn-success" (click)="download()">Download .xml</button> -->
    <button class="btn btn-success" [disabled]="currency<deckInfo?.cost" (click)="purchase()">Purchase</button>
    <button class="btn btn-danger" *ngIf="deckInfo?.creatorid==id" (click)="confirmDelete()">Delete</button>
    <button class="btn btn-warning" *ngIf="deckInfo?.creatorid==id" (click)="edit()">Edit</button>


    <h3><b><u>Main Deck</u></b></h3>

    <span *ngFor = "let card of decklist">
        <span *ngIf="card.cardtype!='Synchro Monster' && card.cardtype!='Xyz Monster' && card.cardtype!='Fusion Monster'">
            <button *ngIf="card!=undefined" (mouseenter)="mouseHovering(card,$event)" (mouseleave)="mouseLeft()" class="btn btn-default"  (click)="goToLink(card.id)" >

                <div class="relative"*ngIf="card.rarity=='common'">
                    <img class ="smallcard" src='https://images.theatticygo.com/card-images/{{card?.id}}.png?{{getTimeStamp()}}'>

                </div>

                <div  class="rare-card" *ngIf="card.rarity!='common'">
                    <img class = " smallcard sparkle" *ngIf="card.rarity=='secret'" src ='https://raw.githubusercontent.com/farisalkhat/CustomCardWebsite/master/src/assets/effects/giphy.gif'>
                    <img class = "smallcard sparkle" *ngIf="card.rarity=='super'" src ='https://raw.githubusercontent.com/farisalkhat/CustomCardWebsite/master/src/assets/effects/super.gif'>
                    <img class = "smallcard sparkle" *ngIf="card.rarity=='ultra'" src ='https://raw.githubusercontent.com/farisalkhat/CustomCardWebsite/master/src/assets/effects/fireworks.gif'>
                    <img class ="smallcard" src='https://images.theatticygo.com/card-images/{{card?.id}}.png?{{getTimeStamp()}}'>

                </div>
            </button>
        </span>

    </span>

    <h3><b><u>Extra Deck</u></b></h3>

    <span *ngFor = "let card of decklist">
        <span *ngIf="card.cardtype=='Synchro Monster' || card.cardtype=='Xyz Monster' || card.cardtype=='Fusion Monster'"><img (mouseenter)="mouseHovering(card,$event)" (mouseleave)="mouseLeft()" (click)="goToLink(card.id)" class ="smallcard" src='https://images.theatticygo.com/card-images/{{card?.id}}.png?{{getTimeStamp()}}'></span>

    </span>

    <!-- <span *ngFor = "let card of decklist">
        <span *ngIf="card?.deck == 'maindeck'"><img (mouseenter)="mouseHovering(card,$event)" (mouseleave)="mouseLeft()" (click)="goToLink(card.id)" class ="smallcard" src='https://images.theatticygo.com/card-images/{{card?.id}}.png?{{getTimeStamp()}}'></span>
    </span>
    <h3><b><u>Side Deck</u></b></h3>
    <span *ngFor = "let card of decklist">
        <span *ngIf="card?.deck == 'sidedeck'"><img (mouseenter)="mouseHovering(card,$event)" (mouseleave)="mouseLeft()" (click)="goToLink(card.id)" class ="smallcard" src='https://images.theatticygo.com/card-images/{{card?.id}}.png?{{getTimeStamp()}}'></span>
    </span>
    <h3><b><u>Extra Deck</u></b></h3>
    <span *ngFor = "let card of decklist">
        <span *ngIf="card?.deck == 'extradeck'"><img (mouseenter)="mouseHovering(card,$event)" (mouseleave)="mouseLeft()" (click)="goToLink(card.id)" class ="smallcard" src='https://images.theatticygo.com/card-images/{{card?.id}}.png?{{getTimeStamp()}}'></span>
    </span>  -->

</div>
