
<div class="container">

  <h1>Welcome to the Draft Format!</h1>
  <h4>For info about how drafts work, click here.</h4>


<div class="row">

  <div class="col"><div class="card" >
    <img src="https://ms.yugipedia.com//thumb/2/2b/JiraiGumo-MADU-EN-VG-artwork.png/413px-JiraiGumo-MADU-EN-VG-artwork.png" class="card-img-top" alt="...">
    <div class="card-body">
      <h5 class="card-title">View Drafts</h5>
      <p class="card-text">See public drafts created by everyone here! </p>
      <a href="/drafts/draftlists" >Explore >></a>
    </div>
  </div></div>
  <div class="col"><div class="card">
    <img src="https://ms.yugipedia.com//f/f3/Blocker-TF05-JP-VG-artwork.png" class="card-img-top" alt="...">
    <div class="card-body">
      <h5 class="card-title">Create a Draft</h5>
      <p class="card-text">Create your own draft to play with friends!</p>
      <a routerLink="/draft-maker" >Create >></a>
    </div>
  </div></div>
  <div class="col"><div class="card">
    <img src="https://uploads1.yugioh.com/card_images/246/detail/Beaver-Warrior.jpg?1375127004" class="card-img-top" alt="...">
    <div class="card-body">
      <h5 class="card-title">My Drafts</h5>
      <p class="card-text">View and Modify your drafts to perfection!</p>
      <a routerLink="/draftmode" >Edit >></a>
    </div>
  </div></div>
</div>

</div>

<!-- 


<div class="container justify-content-center">



        <div class="row">
          <div *ngFor = "let draft of drafts" class="col-auto">

            <a routerLink="/draft-form/{{draft.id}}">
              <div class="card ">
                <div class="crop ">
                  <div class="overlay-div "></div>
                  <img src='https://images.theatticygo.com/card-images/{{draft?.draftimage}}.png' >

                </div>

                <div class="card-img-overlay">
                  <h5 class="text-center align-self-center mx-auto">{{draft.title}} {{draft.id}} </h5>
                </div>
              </div>
            </a>
          </div>
        </div>








</div>

 -->


