<div class="container">

    <form [formGroup] = "matchData" (ngSubmit)="saveMatch()">

        <div class="row">
            <div class="col-auto">
                <label for="Name">Dueled Against:</label>
                <select class="form-control" id="duelist" formControlName="duelist" [ngClass]="{'is-invalid': submitted && f['duelist'].errors}" >
                    <option  *ngFor="let player of players" value="{{player.id}}" >{{player.username}}</option>
                  </select>
                

                <div *ngIf="submitted && f['duelist'].errors" class="invalid-feedback">
                    <div *ngIf="f['duelist'].errors['required']">
                      <p class="red">Umm Duelist required idiot.</p>
                    </div>
                </div>
            
            
            </div>


            <div class="col-auto">
                <label for="Name">Result</label>
                <select class="form-control" id="result" formControlName="result" [ngClass]="{'is-invalid': submitted && f['result'].errors}" >
                    <option value="WON">WON</option>
                    <option value ="LOSS">LOSS</option>
                    <option value ="DRAW">DRAW</option>
                  </select>
                <div *ngIf="submitted && f['result'].errors" class="invalid-feedback">
                    <div *ngIf="f['result'].errors['result']">
                      <p class="red">You need to input a value of at LEAST 100</p>
                    </div>
                </div>
                
            
            
            </div>

            <div class="col-auto">
              <label for="Name">Format</label>
              <select class="form-control" id="gamemode" formControlName="gamemode" [ngClass]="{'is-invalid': submitted && f['gamemode'].errors}" >
                  <option value="Standard">Standard</option>
                  <option value="Collection">Collection</option>
                  <option value ="Sealed Draft">Sealed Draft</option>
                  <option value ="Deck Master">Deck Master</option>
                  <option value ="Cube">Cube</option>
                  <option value ="Balanced">Balanced</option>

                </select>
              <div *ngIf="submitted && f['gamemode'].errors" class="invalid-feedback">
                  <div *ngIf="f['gamemode'].errors['gamemode']">
                    <p class="red">Please input a game mode.</p>
                  </div>
              </div>
              
          
          
          </div>
          <div class="col-auto">
            <label for="Name">Type</label>
            <select class="form-control" id="format" formControlName="format" [ngClass]="{'is-invalid': submitted && f['format'].errors}" >
                <option value="Singles">Singles</option>
                <option value ="Match">Match</option>

              </select>
            <div *ngIf="submitted && f['gamemode'].errors" class="invalid-feedback">
                <div *ngIf="f['gamemode'].errors['gamemode']">
                  <p class="red">Please input a game mode.</p>
                </div>
            </div>
            
        
        
        </div>


            <div class="col-auto">
                <label for="Name">Optional Replay</label>
                <input id ="replay" formControlName ="replay" [ngClass]="{'is-invalid': submitted && f['replay'].errors}" class="form-control" type="text">
                

                <div *ngIf="submitted && f['replay'].errors" class="invalid-feedback">
                    <div *ngIf="f['replay'].errors['replay']">
                      <p class="red">Umm Draft Title required idiot.</p>
                    </div>
                </div>
            
             

              
          
          
            </div>
            
            
        </div>
 
        <div class="row"><div class="col-auto"><button [disabled] = "done==true" class="btn button btn-success" (click)="saveMatch()">Submit</button></div></div>
        
 


    </form>


</div>

