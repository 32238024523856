<div class="container ">



    <div class="row">
      <div *ngFor = "let draft of drafts" class="col-sm-6 col-md-4">

        <div class="col">
          <div class="card" >
            <div class="crop">
              <img src='https://images.theatticygo.com/card-images/{{draft?.draftimage}}.png' >
            </div>
            
            <div class="card-body">
              <h5 class="card-title">{{draft.title}}</h5>
              <p class="card-text">Placeholder description</p>
              <p class="card-text">Creator Placeholder</p>
              <p class="card-text">Date Placeholder</p>
              <p class="card-text">Card Total Placeholder</p>
              <p class="card-text">Views Placeholder</p>
              
              <button class="btn btn-success"routerLink="/drafts/draftlists/{{draft.id}}">>></button>
            </div>
          </div>
        </div>


      </div>
    </div>








</div>



<!-- <div class="container justify-content-center">



        <div class="row">
          <div *ngFor = "let draft of drafts" class="col-auto">

            <a routerLink="/draft-form/{{draft.id}}">
              <div class="card ">
                <div class="crop ">
                  <div class="overlay-div "></div>
                  <img src='https://images.theatticygo.com/card-images/{{draft?.draftimage}}.png' >

                </div>

                <div class="card-img-overlay">
                  <h5 class="text-center align-self-center mx-auto">{{draft.title}} {{draft.id}} </h5>
                </div>
              </div>
            </a>
          </div>
        </div>








</div> -->