
<div class="container">
    <h1>Your Decklists</h1>
    <table class="table">
        <thead class="table-dark">
          <tr>
            <th scope="col">Creator</th>
            <th scope="col">Decklist</th>
            <th scope="col">Description</th>
          </tr>
        </thead>
        <tbody *ngFor="let decklist of decklists ">
            <tr>
              <a routerLink="/players/{{decklist?.creatorid}}"><td>{{decklist?.creator}}</td></a>
              <td><a routerLink='/decklist/{{decklist.id}}'>{{decklist?.name}}</a></td>
              <td><p>{{decklist?.description}}</p></td>      
    
            </tr>
          </tbody>
      </table>

</div>