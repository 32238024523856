<div class="container">
  <div class="row">
    <div class="col-md-3" *ngFor = "let pack of packs">

      <button *ngIf="pack.pack!='placeholder'" class="btn"   >
        <img     class="pack"src='https://images.theatticygo.com/pack-images/{{pack?.id}}.png?{{getTimeStamp()}}'
        >

      </button>

      <button *ngIf="pack.pack=='placeholder'" class="btn"   >
        <img     class="pack"src = 'https://raw.githubusercontent.com/farisalkhat/CustomCardWebsite/master/src/assets/packs/placeholder.png'>
      </button>

    </div>

  </div>

  </div>
