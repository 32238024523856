
<div class="spacing">
</div>


<div class="container">
<div class="row">
    <div class="col-auto">
        <div >

            <img class="avatar" src='https://images.theatticygo.com/profile-images/{{userDetails?.details?.profile_image}}.png' />
        </div>
    </div>
    <div class="mt-auto col-auto">
        <h1 >{{userDetails?.details?.username}}</h1>
    </div>
    <div class="mt-auto col flex-row-reverse d-flex">
        <button routerLink ="/settings" *ngIf="loggedInID==userID" class="button btn btn-success">Edit Profile</button>
    </div>





</div>
<nav class="rounded navbar navbar-default navbar-expand-md navbar-dark ">


    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav mr-auto">

        <li class="nav-item">
          <a class="nav-link" (click)="setPage('Profile')">Profile</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" (click)="setPage('Collection')">Collection</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" (click)="setPage('Decklists')">Decklists</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" (click)="setPage('Packs')">Packs</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" (click)="setPage('Matches')">Matches</a>
        </li>
        <!-- <li class="nav-item">
            <a class="nav-link" >Creations</a>
        </li> -->

      </ul>

    </div>


  </nav>

    <app-player-profile [userId] = "userID" *ngIf="page=='Profile'"></app-player-profile>
    <app-player-collection [userId] = "userID" *ngIf="page=='Collection'"></app-player-collection>
    <app-player-decklists [userId] = "userID" *ngIf="page=='Decklists'"></app-player-decklists>
    <app-player-packs [userId] = "userID" *ngIf="page=='Packs'"></app-player-packs>
    <app-player-matches [userId] = "userID" *ngIf="page=='Matches'"></app-player-matches>




</div>

