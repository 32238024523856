import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Card, CustomcardsService, HoveredCardDetails } from 'src/app/customcards.service';

@Component({
  selector: 'app-cardlist-imageview',
  templateUrl: './cardlist-imageview.component.html',
  styleUrls: ['./cardlist-imageview.component.css']
})
export class CardlistImageviewComponent implements OnInit {
  @Input() cardlist: any[]=[]
  constructor(private _router: Router,private customcardsService: CustomcardsService) { }

  ngOnInit(): void {
    const current = new Date();
    this.timestamp = current.getTime();
  }

  mouseHovering(card: Card, e: MouseEvent) {
    const final = {} as HoveredCardDetails;
    if (e.clientX >= 900) { final.leftPosition = e.clientX - 200; }
    else { final.leftPosition = e.clientX + 2; }
    final.rightPosition = e.clientY - 170;
    final.card = card;
    final.isHovering = true;
    this.customcardsService.HoveredCard(final);
  }
  mouseLeft() {
    this.customcardsService.DisableHoveredCard();
  }

  goToLink(id: string | undefined) {
    const url = this._router.serializeUrl(
      this._router.createUrlTree([`/cards/${id}`])
    );

    window.open(url, '_blank');
  }
  timestamp: number = 0;
  getTimeStamp(){
    return this.timestamp;
  }
}
