<div class="container">
  <span *ngFor = "let draft of drafts">

    <div (click)="editDraft(draft.id,draft.title,draft.draftimage)"  class="card rounded">
      <div class="crop rounded">
        <div class="overlay-div"></div>
        <img src='https://images.theatticygo.com/card-images/{{draft?.draftimage}}.png' >

      </div>

      <div class="card-img-overlay">
        <h5 class="text-center align-self-center mx-auto">{{draft.title}} </h5>
      </div>
    </div>
</span>
</div>
