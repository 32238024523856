
<div class="container">
    <h1>User Decklists</h1>
    <table class="table">
        <thead class="table-dark">
          <tr>
            <th scope="col">Decklist</th>
            <th scope="col">Description</th>
          </tr>
        </thead>
        <tbody *ngFor="let decklist of decklists ">
            <tr>
              <td><a routerLink='/decklist/{{decklist.id}}'>{{decklist?.name}}</a></td>
              <td>{{decklist?.description}}</td>    
    
            </tr>
          </tbody>
      </table>

</div>