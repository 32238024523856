<app-hover-card></app-hover-card>








<div class="container-fluid">
    <div class="row">

      <h1 class="text-center">{{currentPack.title}}</h1>

    </div>
    <div class="row justify-content-center">
          <div class="col-lg-10 text-center">
            <div>

              <span  *ngFor = "let card of randomCards">

                  <button class="btn btn-default" (click)="showDetails(card)" >

                    <div (click)="goToLink(card.id)" (mouseenter)="mouseHovering(card,$event)" (mouseleave)="mouseLeft()" class="card" [@cardFlip]="this.state">
                      <div class="face back">
                        <img src="assets/cardimages/Default.png" />

                      </div>
                        <div class="face front" *ngIf="card.rarity=='common'">
                          <img src='https://images.theatticygo.com/card-images/{{card?.id}}.png?{{getTimeStamp()}}'>
                        </div>

                        <div class="face front rare-card" *ngIf="card.rarity!='common'">
                          <img class = "sparkle" *ngIf="card.rarity=='secret'" src ='https://raw.githubusercontent.com/farisalkhat/CustomCardWebsite/master/src/assets/effects/giphy.gif'>
                          <img class = "sparkle" *ngIf="card.rarity=='super'" src ='https://raw.githubusercontent.com/farisalkhat/CustomCardWebsite/master/src/assets/effects/super.gif'>
                          <img class = "sparkle" *ngIf="card.rarity=='ultra'" src ='https://raw.githubusercontent.com/farisalkhat/CustomCardWebsite/master/src/assets/effects/fireworks.gif'>
                          <img src='https://images.theatticygo.com/card-images/{{card?.id}}.png?{{getTimeStamp()}}'>
                        </div>



                        </div>


                        <span style="color:white;" *ngIf="revealed==true && card.rarity=='common'"><b>{{card?.rarity}}</b></span>
                        <span style="color:blue;" *ngIf="revealed==true && card.rarity=='rare' "><b>{{card?.rarity}}</b></span>
                        <span style="color:orangered;" *ngIf="revealed==true && card.rarity=='super'"><b>{{card?.rarity}}</b></span>
                        <span style="color:red;" *ngIf="revealed==true && card.rarity=='ultra'"><b>{{card?.rarity}}</b></span>
                        <span style="color:purple;" *ngIf="revealed==true && card.rarity=='secret'"><b>{{card?.rarity}}</b></span>
                  </button>


              </span>
          </div>
          </div>




    </div>
<div class="container">
      <button *ngIf="sealedmode==false"class="btn btn-success" [disabled]="finishedPacks==true || revealed==true" (click)="revealCards()">Open Pack {{packsOpened}}/{{packAmount}}</button>
      <button *ngIf="sealedmode==true" class="btn btn-success" [disabled]="finishedPacks==true || revealed==true" (click)="revealDraftCards()">Open Pack {{packsOpened}}/{{packAmount}}</button>
      <button class="btn btn-success" [disabled]="finishedPacks==true || revealed==false" (click)="openNextPack()">Open Next Pack</button>

      <select   [(ngModel)] = "binderID" *ngIf="binders.length>0 && sealedmode==false"  aria-label="Default select example">
        <option  *ngFor = "let binder of binders" value="{{binder.id}}">{{binder.title}}</option>
      </select>
      <button  class="btn btn-success" *ngIf="binders.length>0 && sealedmode==false" (click)= "addToBinder()"[disabled]="finishedPacks==false ||revealed==false" >Save to binder</button>
</div>

    <div class="container">




      <form *ngIf="sealedmode==false" [formGroup] = "binderData" (ngSubmit)="submitBinder()">










          <div class="row">
            <div class="col-auto">
                <label for="Name">Binder Name</label>
                <input id ="binderName" formControlName ="binderName" [ngClass]="{'is-invalid': submitted && f['binderName'].errors}" class="form-control" type="text" [(ngModel)] = "binderName">


                <div *ngIf="submitted && f['binderName'].errors" class="invalid-feedback">
                    <div *ngIf="f['binderName'].errors['required']">
                      <p class="red">Give Binder name</p>
                    </div>
                </div>


            </div>
        </div>





          <button class="btn btn-success"  [disabled]="finishedPacks==false ||revealed==false">Save new binder</button>


      </form>




      <!-- <button class="btn btn-success" [disabled]="revealed==false || packsOpened==packAmount" (click)="openNextPack()">Save to Collection</button> -->


    </div>
    <div class="row justify-content-center" *ngIf = "card!= undefined">
      <div class="col-sm-2"><img class="bigcard" href='' routerLink="/cards/{{card.id}}" src='https://images.theatticygo.com/card-images/{{card?.id}}.png?{{getTimeStamp()}}'> </div>
      <div class="col-md-4 cardeffect">

        <b><p *ngIf="monster=='True'">
            <img class="attribute" src={{attribute}}> {{card?.attribute}} <img class="star" src="assets/cardstats/Star.png"> {{card?.level}}
        </p></b>

        <p *ngIf="monster=='False'">
            <img class="attribute" src={{attribute}}> {{card?.attribute}}
        </p>

        <h3>{{card?.name}}</h3>
        <h4 *ngIf="monster=='False'"><img class="type" src={{stType}}> {{card?.cardtype}} </h4>
        <h4 *ngIf="monster=='True'"> {{card?.cardtype}} </h4>



        <b>

            <p *ngIf="monster=='True'"><img class="type" src={{mType}}> {{card?.type}}</p>


            <p *ngIf="monster=='True'">ATK/{{card?.atk}}  DEF/{{card?.def}}</p>
            <p> {{card?.effect}}</p>
        </b>

      </div>

    </div>





    <div class="col-lg-12">
      <h2>Current Cards</h2>
      <span *ngFor = "let card of currentOpened">
          <img class ="smallcard" href='' (mouseenter)="mouseHovering(card,$event)" (mouseleave)="mouseLeft()" (click)="goToLink(card.id)" src='https://images.theatticygo.com/card-images/{{card?.id}}.png?{{getTimeStamp()}}'>
      </span>
    </div>

    <div class="col-lg-12">
      <h2>Card List</h2>
      <ul *ngFor = "let card of currentOpened">
        <li> <b>{{card?.name}}</b>, {{card?.cardtype}}, <b>{{card?.creator}}</b></li>
      </ul>


    </div>

    </div>
