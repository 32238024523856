<app-hover-card></app-hover-card>
<div class="container">

    <h1><b><u>{{decklistinfo?.name}}</u></b></h1>
    <h2>By {{decklistinfo?.creator}}</h2>
    <button class="btn btn-success" (click)="download()">Download .xml</button>
    <button class="btn btn-danger" *ngIf="decklistinfo?.creatorid==id" (click)="delete()">Delete</button>
    <button class="btn btn-warning" *ngIf="decklistinfo?.creatorid==id" (click)="edit()">Edit</button>

    <p class="float-right">Views: {{decklistinfo?.views}}</p>


    <span [innerHTML]="decklistinfo?.body"></span>

    <h3><b><u>Main Deck</u></b></h3>
    <span *ngFor = "let card of decklist">
        <span *ngIf="card?.deck == 'maindeck'"><img (mouseenter)="mouseHovering(card,$event)" (mouseleave)="mouseLeft()" (click)="goToLink(card.id)" class ="smallcard" src='https://images.theatticygo.com/card-images/{{card?.id}}.png?{{getTimeStamp()}}'></span>
    </span>
    <h3><b><u>Side Deck</u></b></h3>
    <span *ngFor = "let card of decklist">
        <span *ngIf="card?.deck == 'sidedeck'"><img (mouseenter)="mouseHovering(card,$event)" (mouseleave)="mouseLeft()" (click)="goToLink(card.id)" class ="smallcard" src='https://images.theatticygo.com/card-images/{{card?.id}}.png?{{getTimeStamp()}}'></span>
    </span>
    <h3><b><u>Extra Deck</u></b></h3>
    <span *ngFor = "let card of decklist">
        <span *ngIf="card?.deck == 'extradeck'"><img (mouseenter)="mouseHovering(card,$event)" (mouseleave)="mouseLeft()" (click)="goToLink(card.id)" class ="smallcard" src='https://images.theatticygo.com/card-images/{{card?.id}}.png?{{getTimeStamp()}}'></span>
    </span>

</div>
