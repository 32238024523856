<app-hover-card></app-hover-card>

<h1 class="text-center">{{packInfo.title}}</h1>
<h4 class="text-center">Created by <a routerLink="/players/{{packInfo.creatorid}}">{{packInfo.creator}}</a> |
    Pack Size: <span *ngIf="packInfo.packsize=='large'">200 cards, 13 per pack</span>
    <span *ngIf="packInfo.packsize=='medium'">100 cards, 9 per pack</span>
    <span *ngIf="packInfo.packsize=='small'">31 cards, 3 per pack</span>



</h4>

<div class="container">
    <span class="packdescription" [innerHTML]="packInfo.packdescription"></span>


<div *ngIf="packcards.length>0">


    <h2>Secret Rares</h2>
    <span *ngFor = "let card of packcards">
        <button  *ngIf="card.rarity=='secret'" routerLink="/cards/{{card.id}}" (mouseenter)="mouseHovering(card,$event)" (mouseleave)="mouseLeft()" class="btn btn-default" >



            <div  class="rare-card">
                <img class = "smallcard sparkle" *ngIf="card.rarity=='secret'" src ='https://raw.githubusercontent.com/farisalkhat/CustomCardWebsite/master/src/assets/effects/giphy.gif'>
                <img class ="smallcard" src='https://images.theatticygo.com/card-images/{{card?.id}}.png?{{getTimeStamp()}}'>

            </div>



              <!-- <span  *ngIf=" card.rarity=='common'"><b>{{card?.rarity}}</b></span>
                <span style="color:blue;" *ngIf=" card.rarity=='rare' "><b>{{card?.rarity}}</b></span>
                <span style="color:orangered;" *ngIf=" card.rarity=='super'"><b>{{card?.rarity}}</b></span>
                <span style="color:red;" *ngIf=" card.rarity=='ultra'"><b>{{card?.rarity}}</b></span>
                <span style="color:purple;" *ngIf=" card.rarity=='secret'"><b>{{card?.rarity}}</b></span>    -->

        </button>

    </span>
    <h2>Ultra Rares</h2>
    <span *ngFor = "let card of packcards">
        <button   *ngIf="card.rarity=='ultra'"routerLink="/cards/{{card.id}}" (mouseenter)="mouseHovering(card,$event)" (mouseleave)="mouseLeft()" class="btn btn-default" >



                <div   class="rare-card">
                    <img class = "smallcard sparkle" *ngIf="card.rarity=='ultra'" src ='https://raw.githubusercontent.com/farisalkhat/CustomCardWebsite/master/src/assets/effects/fireworks.gif'>
                    <img class ="smallcard" src='https://images.theatticygo.com/card-images/{{card?.id}}.png?{{getTimeStamp()}}'>

                </div>



                  <!-- <span  *ngIf=" card.rarity=='common'"><b>{{card?.rarity}}</b></span>
                    <span style="color:blue;" *ngIf=" card.rarity=='rare' "><b>{{card?.rarity}}</b></span>
                    <span style="color:orangered;" *ngIf=" card.rarity=='super'"><b>{{card?.rarity}}</b></span>
                    <span style="color:red;" *ngIf=" card.rarity=='ultra'"><b>{{card?.rarity}}</b></span>
                    <span style="color:purple;" *ngIf=" card.rarity=='secret'"><b>{{card?.rarity}}</b></span>    -->

        </button>
    </span>

    <h2>Super Rares</h2>
    <span *ngFor = "let card of packcards">
        <button   *ngIf="card.rarity=='super'"routerLink="/cards/{{card.id}}"(mouseenter)="mouseHovering(card,$event)" (mouseleave)="mouseLeft()" class="btn btn-default" >



            <div   class="rare-card" >
                <img class = "smallcard sparkle" *ngIf="card.rarity=='super'" src ='https://raw.githubusercontent.com/farisalkhat/CustomCardWebsite/master/src/assets/effects/super.gif'>
                <img class ="smallcard" src='https://images.theatticygo.com/card-images/{{card?.id}}.png?{{getTimeStamp()}}'>

            </div>


              <!-- <span  *ngIf=" card.rarity=='common'"><b>{{card?.rarity}}</b></span>
                <span style="color:blue;" *ngIf=" card.rarity=='rare' "><b>{{card?.rarity}}</b></span>
                <span style="color:orangered;" *ngIf=" card.rarity=='super'"><b>{{card?.rarity}}</b></span>
                <span style="color:red;" *ngIf=" card.rarity=='ultra'"><b>{{card?.rarity}}</b></span>
                <span style="color:purple;" *ngIf=" card.rarity=='secret'"><b>{{card?.rarity}}</b></span>    -->

        </button>

    </span>

    <h2>Rares</h2>
    <span *ngFor = "let card of packcards">
        <button   *ngIf="card.rarity=='rare'"routerLink="/cards/{{card.id}}" (mouseenter)="mouseHovering(card,$event)" (mouseleave)="mouseLeft()" class="btn btn-default" >



                <div   class="rare-card" >
                    <img class ="smallcard" src='https://images.theatticygo.com/card-images/{{card?.id}}.png?{{getTimeStamp()}}'>

                </div>


                  <!-- <span  *ngIf=" card.rarity=='common'"><b>{{card?.rarity}}</b></span>
                    <span style="color:blue;" *ngIf=" card.rarity=='rare' "><b>{{card?.rarity}}</b></span>
                    <span style="color:orangered;" *ngIf=" card.rarity=='super'"><b>{{card?.rarity}}</b></span>
                    <span style="color:red;" *ngIf=" card.rarity=='ultra'"><b>{{card?.rarity}}</b></span>
                    <span style="color:purple;" *ngIf=" card.rarity=='secret'"><b>{{card?.rarity}}</b></span>    -->

        </button>

    </span>

    <h2>Commons</h2>
    <span *ngFor = "let card of packcards">

        <button  *ngIf="card.rarity=='common'"  routerLink="/cards/{{card.id}}" (mouseenter)="mouseHovering(card,$event)" (mouseleave)="mouseLeft()" class="btn btn-default" >

            <div  class="relative">
                <img class ="smallcard" src='https://images.theatticygo.com/card-images/{{card?.id}}.png?{{getTimeStamp()}}'>

            </div>





              <!-- <span  *ngIf=" card.rarity=='common'"><b>{{card?.rarity}}</b></span>
                <span style="color:blue;" *ngIf=" card.rarity=='rare' "><b>{{card?.rarity}}</b></span>
                <span style="color:orangered;" *ngIf=" card.rarity=='super'"><b>{{card?.rarity}}</b></span>
                <span style="color:red;" *ngIf=" card.rarity=='ultra'"><b>{{card?.rarity}}</b></span>
                <span style="color:purple;" *ngIf=" card.rarity=='secret'"><b>{{card?.rarity}}</b></span>    -->

        </button>
    </span>



</div>

</div>
