import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Card, CustomcardsService, DraftCard } from 'src/app/customcards.service';

@Component({
  selector: 'app-view-draft-details',
  templateUrl: './view-draft-details.component.html',
  styleUrls: ['./view-draft-details.component.css']
})
export class ViewDraftDetailsComponent implements OnInit {
  draftid!: number;
  draft:any = {};
  draftInfo:any;
  customDraft: [] = [];
  cards!: Card[];
  draftSize:number = 0;
  constructor(private route: ActivatedRoute, public router: Router, private customcardsService: CustomcardsService) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe((paramMap) => {
      this.draftid = Number(paramMap.get('draftid'))
      this.customcardsService.getDraftByID(this.draftid).subscribe(
        res => {
          this.draft = res;
          this.draftInfo = this.draft['draft_info']
          this.cards = this.draft['cards']
          this.draftSize = this.cards.length;



          this.cards.sort((a, b) =>

            {
              if(a.cardtype.includes('Monster')){
                  if(b.cardtype.includes('Spell') || b.cardtype.includes('Trap')){
                      return -1
                  }
                  else{
                      if(a.name > b.name){
                          return 1
                      }
                      else{
                          return -1
                      }

                  }
              }
              if(a.cardtype.includes('Spell')){
                  if(b.cardtype.includes('Monster')){
                      return 1
                  }
                  else if(b.cardtype.includes('Trap')){
                      return -1
                  }
                  else{
                      if(a.name > b.name){
                          return 1
                      }
                      else{
                          return -1
                      }
                  }

              }
              if(a.cardtype.includes('Trap')){
                  if(b.cardtype.includes('Monster') || b.cardtype.includes('Spell')){
                      return 1
                  }
                  else{
                      if(a.name > b.name){
                          return 1
                      }
                      else{
                          return -1
                      }
                  }
              }

              return 1}





            )


        }
      )


    });
  }

}
