<div class="container">

    <div class="center">
      <img class="card-img-top" src="{{article.header_img}}" alt="Card image cap">
    </div>
    <!-- <div class="card center " style="width:903px;">
        <img class="card-img-top" src="{{article.header_img}}" alt="Card image cap">
        <div class="card-body" >
            <h1 class="text-center">{{article?.title}}</h1>
          <p class="card-text text-center" >{{article.header}}</p>
          
        </div>
      </div> -->


    <h1>{{article?.title}}<button class="btn btn-danger" *ngIf="article?.author==id" (click)="confirmDelete()">Delete</button>  
      <button class="btn btn-warning" *ngIf="article?.author==id" routerLink="/articles/edit/{{articleid}}">Edit</button></h1>
    
    <h2>{{article?.header}}</h2>
    <p>Article written by <a routerLink="/players/{{article?.author}}">{{article?.username}}</a> | Views: {{article?.views}} </p>
    

    <span [innerHTML]="article.body"></span>
</div>