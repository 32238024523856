<div class="container">
  <div class="row" (keydown.enter)="submitSearch()">
    <div class="row">
        <label for="Name">Search by username</label>
        <input class="form-control" type="text" [(ngModel)] = "searchName">
        <button class="btn btn-success" (click)="submitSearch()">Search</button>
    </div>
  </div>
</div>

<app-player-home *ngIf="searchPage===false"></app-player-home>
<app-player-search [searchResultsFromParent]="playerSearchResults" *ngIf="searchPage===true"></app-player-search>