import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-structure-deck-edit',
  templateUrl: './structure-deck-edit.component.html',
  styleUrls: ['./structure-deck-edit.component.css']
})
export class StructureDeckEditComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
