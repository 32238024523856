<app-hover-card></app-hover-card>
<h2 class="text-center">The Attic Packs</h2>
<div class="container ">
    <span class="binder">Pack: </span>
    <select  class="form-control-lg" [(ngModel)] = "packID" aria-label="Default select example">
        <option  *ngFor = "let pack of packs" value="{{pack.packid}}">{{pack.title}}</option>
      </select>
    <button class="clickbutton btn-success btn" (click)="selectPack()">Select Pack</button>



    <div *ngIf="userPackCollection.length>0">

        <h2>Secret Rares</h2>
        <span *ngFor = "let card of userPackCollection">
            <button  *ngIf="card.rarity=='secret'" routerLink="/cards/{{card.id}}" (mouseenter)="mouseHovering(card,$event)" (mouseleave)="mouseLeft()" class="btn btn-default" >



                <div  *ngIf="card.copies>0"class="rare-card">
                    <img class = "smallcard sparkle" *ngIf="card.rarity=='secret'" src ='https://raw.githubusercontent.com/farisalkhat/CustomCardWebsite/master/src/assets/effects/giphy.gif'>
                    <img class ="smallcard" src='https://images.theatticygo.com/card-images/{{card?.id}}.png?{{getTimeStamp()}}'>
                    <div class="littlenumber position-absolute bottom-0 end-0">{{card.copies}}</div>
                </div>

                <div  *ngIf="card.copies==0" class="rare-card no-card">
                    <img class = "smallcard sparkle" *ngIf="card.rarity=='secret'" src ='https://raw.githubusercontent.com/farisalkhat/CustomCardWebsite/master/src/assets/effects/giphy.gif'>
                    <img class ="smallcard" src='https://images.theatticygo.com/card-images/{{card?.id}}.png?{{getTimeStamp()}}'>
                    <div class="littlenumber position-absolute bottom-0 end-0">{{card.copies}}</div>
                </div>

                  <!-- <span  *ngIf=" card.rarity=='common'"><b>{{card?.rarity}}</b></span>
                    <span style="color:blue;" *ngIf=" card.rarity=='rare' "><b>{{card?.rarity}}</b></span>
                    <span style="color:orangered;" *ngIf=" card.rarity=='super'"><b>{{card?.rarity}}</b></span>
                    <span style="color:red;" *ngIf=" card.rarity=='ultra'"><b>{{card?.rarity}}</b></span>
                    <span style="color:purple;" *ngIf=" card.rarity=='secret'"><b>{{card?.rarity}}</b></span>    -->

            </button>

        </span>
        <h2>Ultra Rares</h2>
        <span *ngFor = "let card of userPackCollection">
            <button  routerLink="/cards/{{card.id}}" *ngIf="card.rarity=='ultra'"routerLink="/cards/{{card.id}}" (mouseenter)="mouseHovering(card,$event)" (mouseleave)="mouseLeft()" class="btn btn-default" >



                    <div  *ngIf="card.copies>0" class="rare-card">
                        <img class = "smallcard sparkle" *ngIf="card.rarity=='ultra'" src ='https://raw.githubusercontent.com/farisalkhat/CustomCardWebsite/master/src/assets/effects/fireworks.gif'>
                        <img class ="smallcard" src='https://images.theatticygo.com/card-images/{{card?.id}}.png?{{getTimeStamp()}}'>
                        <div class="littlenumber position-absolute bottom-0 end-0">{{card.copies}}</div>
                    </div>

                    <div  *ngIf="card.copies==0" class="rare-card no-card">
                        <img class = "smallcard sparkle" *ngIf="card.rarity=='ultra'" src ='https://raw.githubusercontent.com/farisalkhat/CustomCardWebsite/master/src/assets/effects/fireworks.gif'>
                        <img class ="smallcard" src='https://images.theatticygo.com/card-images/{{card?.id}}.png?{{getTimeStamp()}}'>
                        <div class="littlenumber position-absolute bottom-0 end-0">{{card.copies}}</div>
                    </div>

                      <!-- <span  *ngIf=" card.rarity=='common'"><b>{{card?.rarity}}</b></span>
                        <span style="color:blue;" *ngIf=" card.rarity=='rare' "><b>{{card?.rarity}}</b></span>
                        <span style="color:orangered;" *ngIf=" card.rarity=='super'"><b>{{card?.rarity}}</b></span>
                        <span style="color:red;" *ngIf=" card.rarity=='ultra'"><b>{{card?.rarity}}</b></span>
                        <span style="color:purple;" *ngIf=" card.rarity=='secret'"><b>{{card?.rarity}}</b></span>    -->

            </button>
        </span>

        <h2>Super Rares</h2>
        <span *ngFor = "let card of userPackCollection">
            <button  routerLink="/cards/{{card.id}}" *ngIf="card.rarity=='super'"routerLink="/cards/{{card.id}}"(mouseenter)="mouseHovering(card,$event)" (mouseleave)="mouseLeft()" class="btn btn-default" >



                <div  *ngIf="card.copies>0" class="rare-card" >
                    <img class = "smallcard sparkle" *ngIf="card.rarity=='super'" src ='https://raw.githubusercontent.com/farisalkhat/CustomCardWebsite/master/src/assets/effects/super.gif'>
                    <img class ="smallcard" src='https://images.theatticygo.com/card-images/{{card?.id}}.png?{{getTimeStamp()}}'>
                    <div class="littlenumber position-absolute bottom-0 end-0">{{card.copies}}</div>
                </div>
                <div  *ngIf="card.copies==0" class="rare-card no-card" >
                    <img class = "smallcard sparkle" *ngIf="card.rarity=='super'" src ='https://raw.githubusercontent.com/farisalkhat/CustomCardWebsite/master/src/assets/effects/super.gif'>
                    <img class ="smallcard" src='https://images.theatticygo.com/card-images/{{card?.id}}.png?{{getTimeStamp()}}'>
                    <div class="littlenumber position-absolute bottom-0 end-0">{{card.copies}}</div>
                </div>

                  <!-- <span  *ngIf=" card.rarity=='common'"><b>{{card?.rarity}}</b></span>
                    <span style="color:blue;" *ngIf=" card.rarity=='rare' "><b>{{card?.rarity}}</b></span>
                    <span style="color:orangered;" *ngIf=" card.rarity=='super'"><b>{{card?.rarity}}</b></span>
                    <span style="color:red;" *ngIf=" card.rarity=='ultra'"><b>{{card?.rarity}}</b></span>
                    <span style="color:purple;" *ngIf=" card.rarity=='secret'"><b>{{card?.rarity}}</b></span>    -->

            </button>

        </span>

        <h2>Rares</h2>
        <span *ngFor = "let card of userPackCollection">
            <button  routerLink="/cards/{{card.id}}" *ngIf="card.rarity=='rare'"routerLink="/cards/{{card.id}}"(mouseenter)="mouseHovering(card,$event)" (mouseleave)="mouseLeft()" class="btn btn-default" >



                    <div  *ngIf="card.copies>0" class="rare-card" >
                        <img class ="smallcard" src='https://images.theatticygo.com/card-images/{{card?.id}}.png?{{getTimeStamp()}}'>
                        <div class="littlenumber position-absolute bottom-0 end-0">{{card.copies}}</div>
                    </div>
                    <div  *ngIf="card.copies==0" class="rare-card no-card " >
                        <img class ="smallcard" src='https://images.theatticygo.com/card-images/{{card?.id}}.png?{{getTimeStamp()}}'>
                        <div class="littlenumber position-absolute bottom-0 end-0">{{card.copies}}</div>
                    </div>

                      <!-- <span  *ngIf=" card.rarity=='common'"><b>{{card?.rarity}}</b></span>
                        <span style="color:blue;" *ngIf=" card.rarity=='rare' "><b>{{card?.rarity}}</b></span>
                        <span style="color:orangered;" *ngIf=" card.rarity=='super'"><b>{{card?.rarity}}</b></span>
                        <span style="color:red;" *ngIf=" card.rarity=='ultra'"><b>{{card?.rarity}}</b></span>
                        <span style="color:purple;" *ngIf=" card.rarity=='secret'"><b>{{card?.rarity}}</b></span>    -->

            </button>

        </span>

        <h2>Commons</h2>
        <span *ngFor = "let card of userPackCollection">

            <button  *ngIf="card.rarity=='common'" routerLink="/cards/{{card.id}}" routerLink="/cards/{{card.id}}"(mouseenter)="mouseHovering(card,$event)" (mouseleave)="mouseLeft()" class="btn btn-default" >

                <div *ngIf="card.copies>0" class="relative">
                    <img class ="smallcard" src='https://images.theatticygo.com/card-images/{{card?.id}}.png?{{getTimeStamp()}}'>
                    <div class="littlenumber position-absolute bottom-0 end-0">{{card.copies}}</div>
                </div>

                <div *ngIf="card.copies==0" class="relative no-card">
                    <img class ="smallcard" src='https://images.theatticygo.com/card-images/{{card?.id}}.png?{{getTimeStamp()}}'>
                    <div class="littlenumber position-absolute bottom-0 end-0">{{card.copies}}</div>
                </div>



                  <!-- <span  *ngIf=" card.rarity=='common'"><b>{{card?.rarity}}</b></span>
                    <span style="color:blue;" *ngIf=" card.rarity=='rare' "><b>{{card?.rarity}}</b></span>
                    <span style="color:orangered;" *ngIf=" card.rarity=='super'"><b>{{card?.rarity}}</b></span>
                    <span style="color:red;" *ngIf=" card.rarity=='ultra'"><b>{{card?.rarity}}</b></span>
                    <span style="color:purple;" *ngIf=" card.rarity=='secret'"><b>{{card?.rarity}}</b></span>    -->

            </button>
        </span>





    </div>

</div>
