<app-hover-card></app-hover-card>

<div class="container">
<h1>Banlist</h1>

<h2>Banned</h2>

<h2>Limited</h2>
<span *ngFor = "let card of cards">
    <button *ngIf="card.tag=='Limited' "(mouseenter)="mouseHovering(card,$event)" (mouseleave)="mouseLeft()" class="btn btn-default"  (click)="goToLink(card.id)" ><img class ="smallcard" src='https://images.theatticygo.com/card-images/{{card?.id}}.png?{{getTimeStamp()}}'></button>
</span>
<h2>Semi-Limited</h2>
<span *ngFor = "let card of cards">
    <button *ngIf="card.tag=='Semi-Limited' "(mouseenter)="mouseHovering(card,$event)" (mouseleave)="mouseLeft()" class="btn btn-default"  (click)="goToLink(card.id)" ><img class ="smallcard" src='https://images.theatticygo.com/card-images/{{card?.id}}.png?{{getTimeStamp()}}'></button>
</span>


</div>
