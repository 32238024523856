import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-cardlist-table',
  templateUrl: './cardlist-table.component.html',
  styleUrls: ['./cardlist-table.component.css']
})
export class CardlistTableComponent implements OnInit {
  @Input() cardlist: any[]=[]
  constructor() { }

  ngOnInit(): void {
  }

}
