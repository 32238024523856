<div class="container">

    <h1>Commander Format</h1>
    <ul>
        <li>Each player must play 60 cards in their Deck.</li>
        <li>Each player must have no more than 1 copy of each card in their Deck.</li>
        <li>At the start of the Duel, each player places their Deck Master in their Extra Monster Zone. If their Deck Master was in their starting hand, they draw a card to replace it.</li>
        <li>Deck Master: Must be a Level 5 or higher monster. The Deck Master can be Tribute Summoned from the Extra Monster Zone. If the Deck Master would leave the field, it is placed in the Extra Monster Zone instead. The Deck Master requires 1 extra Tribute for its Tribute Summon for each time it was returned to the Extra Monster Zone that game.</li>
        <li>Neither player’s Deck can contain any monsters that do not share a Type and/or Attribute with their Deck Master.</li>
    </ul>

    <h1>Collection Format</h1>
    <ul>
        <li>You are only allowed to use cards that you own on the website.</li>
    </ul>

    <h1> Balanced Format</h1>
    <ul>
        <li>40 Card Limit</li>
        <li>MUST have 20 monsters, 10 Spells and 10 Traps.</li>
        <li>Max copy limit is 2.</li>
        <li>You're only allowed to have 3 monsters with multiple copies.</li>
        <li>You're only allowed to have 1 Spell with multiple copies.
        <li>You're only allowed to have 1 Trap with multiple copies.</li>
    </ul>

    <h1>Gambling Format</h1>
    <ul>
        <li>Matches are best 2 out of 3.</li>
        <li>Prior to the match, the players agree on a Chegger’s Dollar™ wager, in multiples of 50.</li>
        <li>Once per turn, the turn player can pay 1000 LP or take out a 1000 LP Casino Marker, then draw 1 card.</li>
        <li>Casino Marker: The amount of Life Points paid is taken out from the player’s starting Life Points during the next game instead. Casino Markers cannot be taken out during game 3.</li>
        <li>Once per turn, the turn player can pay 1000 LP or take out a 1000 LP Casino Marker, then send 1 “Dice Modulation” from their Deck to the Graveyard. This turn, it is treated as if that player had activated “Dice Modulation”.</li>
        <li>At the start of the Duel, if either player’s Deck contains a “Jackpot 7”, “Second Coin Toss”, or “Dice Re-Roll”, they can place 1 of those cards in their Spell/Trap Card Zone. If the selected card was in their starting hand, they draw a card to replace it.</li>
        <li>Whenever a player would pay Life Points or take damage, they can take out a Casino Marker of the equivalent value instead.</li>
    </ul>



</div>



