<app-hover-card></app-hover-card>

<div class="container">
  <h1 class="text-center">This is a Pseudo Draft. You open a pack of 15 from the entire database, select 1, then open a
    pack with one less card, and you repeat the process until you have 60 cards. </h1>

</div>










<div class="container-fluid">
  <div class="row">
    <div class="container">


      <button class="btn btn-success" (click)="revealCards()">Reveal Cards</button>





      <!-- <button *ngIf="customButton1=='afres'" class="btn btn-success"(click)="shuffleUnique('afres')">Afres Pack</button>
          <button *ngIf="customButton1=='gergoos'" class="btn btn-success"(click)="shuffleUnique('gergoos')">Gergoos Pack</button>
          <button *ngIf="customButton1=='swampus'" class="btn btn-success"(click)="shuffleUnique('swampus')">jirai_gumo_2200 Pack</button>
          <button *ngIf="customButton1=='monsters'" class="btn btn-success"(click)="shuffleUnique('monsters')">Monster Pack</button>
          <button *ngIf="customButton1=='spells'" class="btn btn-success"(click)="shuffleUnique('spells')">Spell Pack</button>
          <button *ngIf="customButton1=='traps'" class="btn btn-success"(click)="shuffleUnique('traps')">Trap Pack</button>
          <button *ngIf="customButton1=='richie'" class="btn btn-success"(click)="shuffleUnique('richie')">Charge301 Pack</button>

          <button *ngIf="customButton2=='afres'" class="btn btn-success"(click)="shuffleUnique('afres')">Afres Pack</button>
          <button *ngIf="customButton2=='gergoos'" class="btn btn-success"(click)="shuffleUnique('gergoos')">Gergoos Pack</button>
          <button *ngIf="customButton2=='swampus'" class="btn btn-success"(click)="shuffleUnique('swampus')">jirai_gumo_2200 Pack</button>
          <button *ngIf="customButton2=='monsters'" class="btn btn-success"(click)="shuffleUnique('monsters')">Monster Pack</button>
          <button *ngIf="customButton2=='spells'" class="btn btn-success"(click)="shuffleUnique('spells')">Spell Pack</button>
          <button *ngIf="customButton2=='traps'" class="btn btn-success"(click)="shuffleUnique('traps')">Trap Pack</button>
          <button *ngIf="customButton2=='richie'" class="btn btn-success"(click)="shuffleUnique('richie')">Charge301 Pack</button> -->




      <!-- <button class="btn btn-success"(click)="shuffleCards()">Open Next Pack</button> -->


    </div>

  </div>
  <div class="row">
    <div class="col-md-8 text-center colRand">
      <div>

        <span *ngFor="let card of randomCards">

          <button (click)="goToLinkDrafting(card.id)" (mouseenter)="mouseHovering(card,$event)" (mouseleave)="mouseLeft()"
            class=" btn btn-default btncard" (contextmenu)="rightAddDraftCard($event,card)">

            <div class="card" [@cardFlip]="this.state">
              <div class="face back">
                <img src="assets/cardimages/Default.png" />
              </div>
              <div class="face front">
                <img src='https://images.theatticygo.com/card-images/{{card?.id}}.png?{{getTimeStamp()}}'>
              </div>
            </div>

          </button>

        </span>


      </div>
    </div>
    <div class="colDraft col">
      <h2>Current Cards ( {{cardsSelected}}/60)</h2>
      <span *ngFor="let card of currentDraft">
        <button (click)="goToLink(card.id)" (mouseenter)="mouseHoverDrafted(card,$event)" (mouseleave)="mouseLeft()"
          class=" btn btn-default btncard"><img class="smallcard"
            src='https://images.theatticygo.com/card-images/{{card?.id}}.png?{{getTimeStamp()}}'></button>

      </span>
    </div>

    <!-- <div class="col colCardInfo cardeffect">
            <button type="button" *ngIf="draftCardSelected==true"class="btn btn-success addcard" (click)="addCard()">Add</button>
            <div *ngIf = "card!= undefined">
              <h3>{{card?.name}}
                <span *ngIf="monster=='False'"> / {{card?.cardtype}} <img class="attribute" src={{attribute}}> <img class="type" src={{stType}}>{{card?.attribute}}</span>
                <span *ngIf="monster=='True'"> / {{card?.cardtype}} </span>

              </h3>

              <h4 *ngIf="monster=='False'"><img class="type" src={{stType}}> {{card?.cardtype}} </h4>
              <h4 *ngIf="monster=='True'"> {{card?.cardtype}} </h4>

                <p>
              <b *ngIf="monster=='True'">
                <img class="attribute" src={{attribute}}> {{card?.attribute}} <img class="star" src="assets/cardstats/Star.png"> {{card?.level}}
            </b>


          </p>




              <b>

                  <p *ngIf="monster=='True'"><img class="type" src={{mType}}> {{card?.type}}</p>


                  <p *ngIf="monster=='True'">ATK/{{card?.atk}}  DEF/{{card?.def}}</p>
                  <p> {{card?.effect}}</p>
              </b>


            <img class="bigcard" src='https://images.theatticygo.com/card-images/{{card?.id}}.png?{{getTimeStamp()}}'>

            </div>







          </div> -->




  </div>







  <!-- <div class="col-lg-12">
      <h2>Current Cards</h2>
      <span *ngFor = "let card of currentDraft">
          <img class ="smallcard" src='https://images.theatticygo.com/card-images/{{card?.id}}.png?{{getTimeStamp()}}'>
      </span>
    </div> -->

  <div class="col-lg-12">
    <button class="btn btn-success" (click)="exportList()">Export XML</button>
    <button class="btn btn-success" (click)="GoToDeckEditor()">Deck Editor</button>
    <h2>Card List</h2>
    <ul *ngFor="let card of sortedList">
      <li> <b>{{card?.name}}</b>, {{card?.cardtype}}, <b>{{card?.creator}}</b>, copies: {{card?.copies}}</li>
    </ul>


  </div>

</div>
