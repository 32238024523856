
    <div class="col-sm-5">
  
      
    <form  [formGroup] = "accountInfo" (ngSubmit)="changeInfo()">
        
      <h1 class="h2 mb-3 font-weight-normal">Account</h1>
  
  

  
  
  
      <label for="inputEmail" class="sr-only">Email address</label>
  
      <input  formControlName = "email" name="email" type="email" id="inputEmail" class="form-control"  required autofocus
      [ngClass]="{'is-invalid': submitted && f['email'].errors}">
  
      <div *ngIf="submitted && f['email'].errors" class="invalid-feedback">
        peepee
      </div>
  
      <label for="inputPassword" class="sr-only">Current Password</label>
      <input  formControlName = "password" name="password" type="password" id="inputPassword" class="form-control" placeholder="Password" required
      [ngClass]="{'is-invalid': submitted && f['password'].errors}">
  
      <div *ngIf="submitted && f['password'].errors" class="invalid-feedback">
        poopoo
      </div>



      <label for="inputPassword" class="sr-only">New Password</label>
      <input  formControlName = "newpassword" name="newpassword" type="password" id="newpassword" class="form-control" placeholder="Password" required
      [ngClass]="{'is-invalid': submitted && f['newpassword'].errors}">
  
      <div *ngIf="submitted && f['newpassword'].errors" class="invalid-feedback">
        poopoo
      </div>
  
      <button  class="btn btn-lg btn-success btn-block" type="submit">Save Changes</button>
      
    </form>
  </div>
  <div class="extra-space"></div>

  