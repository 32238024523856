<ul class="nav nav-tabs" id="myTab" role="tablist">
    <li class="nav-item" role="presentation">
      <button class="nav-link active" id="table-tab" data-bs-toggle="tab" data-bs-target="#table" type="button" role="tab" aria-controls="table" aria-selected="true">Table View</button>
    </li>
    <li class="nav-item" role="presentation">
      <button class="nav-link" id="text-tab" data-bs-toggle="tab" data-bs-target="#text" type="button" role="tab" aria-controls="text" aria-selected="false">Text View</button>
    </li>
    <li class="nav-item" role="presentation">
      <button class="nav-link" id="image-tab" data-bs-toggle="tab" data-bs-target="#image" type="button" role="tab" aria-controls="image" aria-selected="false">Image View</button>
    </li>
    <li class="nav-item" role="presentation">
        <button class="nav-link" id="changelog-tab" data-bs-toggle="tab" data-bs-target="#changelog" type="button" role="tab" aria-controls="changelog" aria-selected="false">Changelog</button>
      </li>
    <li class="nav-item" role="presentation">
        <button class="nav-link" id="report-tab" data-bs-toggle="tab" data-bs-target="#report" type="button" role="tab" aria-controls="report" aria-selected="false">Report</button>
      </li>
  </ul>
  <div class="tab-content" id="myTabContent">
    <div class="tab-pane fade show active" id="table" role="tabpanel" aria-labelledby="table-tab"><p>tabletab</p></div>
    <div class="tab-pane fade" id="text" role="tabpanel" aria-labelledby="text-tab">


        <app-cardlist-textview [cardlist]="cardlist"></app-cardlist-textview>



    </div>
    <div class="tab-pane fade" id="image" role="tabpanel" aria-labelledby="image-tab">
      <app-cardlist-imageview [cardlist]="cardlist"></app-cardlist-imageview>
    </div>
    <div class="tab-pane fade" id="changelog" role="tabpanel" aria-labelledby="changelog-tab"><p>changetab</p></div>
    <div class="tab-pane fade" id="report" role="tabpanel" aria-labelledby="report-tab"><p>reporttab</p></div>
  </div>
