
<div class="container">

    <div class="row">
        <div class="col-sm-2">
            <div class="row">
                <h1>Settings</h1>
            </div>
            <div class="row">
                <button class="btn button btn-success"(click)="setPage('Profile')">Profile</button>
            </div>
            <div class="row">
                <button class="btn button btn-success" (click)="setPage('Account')">Account</button>
            </div>

            
        
        </div>
        <div *ngIf="page=='Profile'" class="col-lg"><app-about></app-about></div>
        <div *ngIf="page=='Account'" class="col-lg"><app-account></app-account></div>
        

    </div>


</div>









