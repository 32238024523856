<app-hover-card></app-hover-card>
<span *ngFor="let card of cardlist">
  <button *ngIf="card!=undefined" (mouseenter)="mouseHovering(card,$event)" (mouseleave)="mouseLeft()"
      class="btn btn-default"
      (click)="goToLink(card?.id)">
      <div class="relative">
          <img class="smallcard" src='https://images.theatticygo.com/card-images/{{card?.id}}.png?{{getTimeStamp()}}'>
      </div>
  </button>
</span>
