<div class="container">


  <ul class="list-group list-group-flush" >
    <li *ngFor="let card of cardlist" class="list-group-item"><p>{{card.name}}</p></li>


  </ul>



</div>


