import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-cardlist-textview',
  templateUrl: './cardlist-textview.component.html',
  styleUrls: ['./cardlist-textview.component.css']
})
export class CardlistTextviewComponent implements OnInit {
  @Input() cardlist: any[]=[]
  constructor() { }

  ngOnInit(): void {
  }

}
