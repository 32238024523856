<nav class="rounded navbar navbar-default navbar-expand-md navbar-dark ">

  
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav mr-auto">

        <li class="nav-item">
          <a class="nav-link" (click)="setPage('Profile')">Profile</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" (click)="setPage('Profile')">Collection</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" (click)="setPage('Profile')">Decklists</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" (click)="setPage('Profile')">Packs</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" (click)="setPage('Profile')">Matches</a>
        </li>
        <!-- <li class="nav-item">
            <a class="nav-link" >Creations</a>
        </li> -->
        
      </ul>

    </div>

    
  </nav>

  