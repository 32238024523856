<app-hover-card></app-hover-card>

<h1 class="text-center">Card Collection</h1>


<!-- <div class="container ">
    <span class="binder">Binder: </span>
    <select  class="form-control-lg" [(ngModel)] = "binderID" aria-label="Default select example">
        <option  *ngFor = "let binder of binders" value="{{binder.id}}">{{binder.title}}</option>
      </select>
    <button class="clickbutton btn-success btn" (click)="selectBinder()">Select Binder</button>
</div> -->
<div class="row">


    <div *ngIf="currentCards.length>0" class="col justify-content-between">
        <div class="row" (keydown.enter)="submitSearch()">
            <div class="row">
                <label for="Name">Name</label>
                <input class="form-control" type="text" [(ngModel)] = "filters.name">
                <button class="btn btn-success" (click)="submitSearch()">Search</button>
            </div>
        </div>

            <span  *ngFor = "let card of currentCards">

                <button  (click)="goToLink(card.id)" (mouseenter)="mouseHovering(card,$event)" (mouseleave)="mouseLeft()" class="btn btn-default" >

                    <div class="relative"*ngIf="card.rarity=='common'">
                        <img class ="smallcard" src='https://images.theatticygo.com/card-images/{{card?.id}}.png?{{getTimeStamp()}}'>
                        <div class="littlenumber position-absolute bottom-0 end-0">{{card.copies}}</div>
                    </div>

                    <div  class="rare-card" *ngIf="card.rarity!='common'">
                        <img class = " smallcard sparkle" *ngIf="card.rarity=='secret'" src ='https://raw.githubusercontent.com/farisalkhat/CustomCardWebsite/master/src/assets/effects/giphy.gif'>
                        <img class = "smallcard sparkle" *ngIf="card.rarity=='super'" src ='https://raw.githubusercontent.com/farisalkhat/CustomCardWebsite/master/src/assets/effects/super.gif'>
                        <img class = "smallcard sparkle" *ngIf="card.rarity=='ultra'" src ='https://raw.githubusercontent.com/farisalkhat/CustomCardWebsite/master/src/assets/effects/fireworks.gif'>
                        <img class ="smallcard" src='https://images.theatticygo.com/card-images/{{card?.id}}.png?{{getTimeStamp()}}'>
                        <div class="littlenumber position-absolute bottom-0 end-0">{{card.copies}}</div>
                    </div>

                      <!-- <span  *ngIf=" card.rarity=='common'"><b>{{card?.rarity}}</b></span>
                        <span style="color:blue;" *ngIf=" card.rarity=='rare' "><b>{{card?.rarity}}</b></span>
                        <span style="color:orangered;" *ngIf=" card.rarity=='super'"><b>{{card?.rarity}}</b></span>
                        <span style="color:red;" *ngIf=" card.rarity=='ultra'"><b>{{card?.rarity}}</b></span>
                        <span style="color:purple;" *ngIf=" card.rarity=='secret'"><b>{{card?.rarity}}</b></span>    -->

                    </button>

            </span>


            <div class="row ">
                <div class="col text-center">             <button class="btn btn-success" (click)="prevPage()">Previous</button><button class="btn btn-success" (click)="nextPage()">Next</button>
                </div>
</div>




        <!-- <div class="row">
            <button class="btn btn-success" (click)="nextPage()">Next</button><button class="btn btn-success" (click)="prevPage()">Previous</button>
        </div> -->

    </div>




</div>
