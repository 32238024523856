<div class="container">
    <span *ngFor = "let pack of packs">

      <button *ngIf="pack.pack!='placeholder'" class="btn"  (click)="editPack(pack.id,pack.title)" >
        <img     class="pack" src='https://images.theatticygo.com/pack-images/{{pack?.pack}}.png'>
        <p>{{pack.title}}</p>
      </button>

      <button *ngIf="pack.pack=='placeholder'" class="btn"  (click)="editPack(pack.id,pack.title)" >
        <img     class="pack"src = 'https://raw.githubusercontent.com/farisalkhat/CustomCardWebsite/master/src/assets/packs/placeholder.png'>
        <p>{{pack.title}}</p>
      </button>

  </span>
  </div>
